body {
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  pointer-events: auto;
}

.dark-mode {
  background-color: #000000;
  color: #f5ebc1;
  z-index: 20;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hero-container {
  display: flex;
  justify-content:end;
  align-items: center;
  padding: 1rem;
}

.react-toggle {
  display: none !important;
}

.image-container {
  display: flex;
  justify-content: center;
}

.profile-image-container {
  display: flex;
  justify-content: center;
}

/* Knappar ---------------------------------------------------------------------------------> */
/* .github-btn {
  background-color: #C78059;
  color: white;
  border: none;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 0.7rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 5px 3px 1px rgba(92, 92, 92, 0.514);
} */

.github-btn {
  background-color: #f5ebc1;
  color: rgb(0, 0, 0);
  border: none;
  border-bottom: 4px solid #908A6E;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.7rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  /* box-shadow: 2px 5px 3px 1px rgba(92, 92, 92, 0.514); */
  text-decoration: none;
}

/* .redirect-btn {
  background-color: #C78059;
  color: white;
  border: none;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border-radius: 0.7rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 5px 3px 1px rgba(92, 92, 92, 0.514);
} */

.redirect-btn {
  background-color: #f5ebc1;
  color: black;
  border: none;
  border-bottom: 4px solid #908A6E;
  padding: 0.7rem 1rem 0.7rem 1rem;
  border-radius: 0.7rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  /* box-shadow: 2px 5px 3px 1px rgba(92, 92, 92, 0.514); */
  text-decoration: none;
}

/* button:hover {
  background-color: #976144;
} */

button.contact-button:hover {
  background-color: #805138;
}

.cv-button:hover {
  background-color: #805138;
}

a.redirect-btn:hover, 
a.github-btn:hover {
  background-color: #e2d086;
}

.cv-button, 
.contact-button {
  background-color: #ad633a;
  color: white;
  border: none;
  border-bottom: 4px solid #86553A;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 0.2rem;
  margin: 1.5rem 1.5rem 1.5rem 1rem;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

.darkmode-button {
  display: none;
}

/* Länkar ---------------------------------------------------------------------------------> */

a.btn-link:link, 
a.btn-link:visited, 
a.btn-link:hover,
a.btn-link:active {
  color: white;
  text-decoration: none;
}

a.email-link:link, 
a.email-link:visited,
a.email-link:hover,
a.email-link:active {
  color: black;
}


/* Info - Högst upp ---------------------------------------------------------------------------------> */

/* .projects-container h2,  */
.skills-title {
  text-align: center;
  color: #3E2514;
  margin: 0;
  padding: 1rem 0 1rem 0;
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65rem;
}

.site-title {
  color: #C78059;
  margin: 1.5rem 1.5rem 1.5rem 1.5rem;
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.text-container p {
  margin: 0 1.5rem 0 1.5rem;
}

.profile-image {
  border-radius: 10rem;
  border: solid #A1B999 0.5rem;
  margin-left: 0.5rem;
}

.triangle-bottom{
  position: relative;
  width: 100%;
  height: 150px; /* Justera höjden efter behov */
  bottom: -2.5rem;
  overflow: hidden; /* Detta gör att inget innehåll flyter utanför */
  transform: rotate(.5turn);
  z-index: -1;
}

.triangle-bottom::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 21rem;
  background-color: #C78059; /* Bakgrundsfärg för trianglarna */
  -webkit-mask-image: url('assets/images/triangleNew.svg'); /* Maskering för trianglarna */
  mask-image: url('assets/images/triangleNew.svg'); /* För icke-webkit-baserade webbläsare */
  -webkit-mask-size: 7rem; /* Mindre storlek för att få trianglarna närmare varandra */
  mask-size: 7.5rem;
  -webkit-mask-repeat: repeat-x; /* Upprepa trianglarna längs x-axeln */
  mask-repeat: repeat-x;
  top: 0;
  left: 0;
 
}

/* Projekt - Mitten ---------------------------------------------------------------------------------> */

/* .projects-title {
  color: white;
  font-size: 40px;
  margin: 0;
} */

.triangle-top {
  position: relative;
  width: 100%;
  height: 150px; /* Justera höjden efter behov */
  top: -2.5rem; /* Justera efter ditt innehåll */
  overflow: hidden; /* Detta gör att inget innehåll flyter utanför */
  z-index: -1;
}

.triangle-top::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 21rem;
  background-color: #A1B999; /* Bakgrundsfärg för trianglarna */
  -webkit-mask-image: url('assets/images/triangleNew.svg'); /* Maskering för trianglarna */
  mask-image: url('assets/images/triangleNew.svg'); /* För icke-webkit-baserade webbläsare */
  -webkit-mask-size: 7rem; /* Mindre storlek för att få trianglarna närmare varandra */
  mask-size: 7.5rem;
  -webkit-mask-repeat: repeat-x; /* Upprepa trianglarna längs x-axeln */
  mask-repeat: repeat-x;
  top: 0;
  left: 0;
}

.projects-container {
  background-color: #A1B999;
  padding: 0 0 7rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-1, 
.project-2, 
.project-3, 
.project-4, 
.project-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 5rem;
}

.project-image {
  /* width: 50%; */
  align-self: center;
  /* margin: 0 10rem 0 2rem; */
  /* height: auto; */
  display: block;
  box-shadow: 
  -30px -20px #f5ebc1,
  30px 10px #C78059;
}

.project-text {
  color: black;
  margin: 0 1.5rem 0 1.5rem;
}

.project-title {
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.projects-title {
  text-align: center;
  color: #36462F;
  position: relative;
  top: -5rem;
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.btn-container {
  display: flex;
  column-gap: 1rem;
}

.tech-box {
  background-color: white;
  width: fit-content;
  padding: 0.2rem 0.9rem 0.2rem 0.9rem;
  font-weight: bold;
  border-radius: 0.2rem;
  margin-top: 0;
}

.tech-box-container {
  display: flex;  
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.github-icon {
  width: 1.4rem;
  /* filter: brightness(0) invert(1); */
}

.redirect-icon {
  width: 1.1rem;
  /* filter: brightness(0) invert(1); */
}

/* Skills - Mitten ---------------------------------------------------------------------------------> */
.skills-container {
  background-color: #C78059;
}

.skill-icon {
  width: 3rem;
  filter: brightness(0) invert(1);
}

.skill-icon-sql {
  width: 2.5rem;
  filter: brightness(0) invert(1);
}

.skill-icon-css, 
.skill-icon-html {
  width: 2.4rem;
  filter: brightness(0) invert(1);
}

.skill-icon-js {
  width: 2.8rem;
  filter: brightness(0) invert(1);
}

.skill-icon-php {
  width: 3.5rem;
  filter: brightness(0) invert(1);
}

.skill-icon-gulp {
  width: 2.5rem;
  filter: brightness(0) invert(1);
}

.skill-icon-sass {
  width: 4rem;
  filter: brightness(0) invert(1);
}

.skill-icon-react, 
.skill-icon-vue, 
.skill-icon-laravel {
  width: 3rem;
  filter: brightness(0) invert(1);
}

.skill div {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.7rem;
}

/* .flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
} */

.flex-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    row-gap: 2rem;
    align-items: end;
}

.flex-item {
  flex: 1 1 calc(50% - 20px); /* 2 - kolumner */
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3E2514;
  font-weight: bold;
} 

/* Footer ---------------------------------------------------------------------------------> */
footer {
  text-align: center;
  margin-top: 9rem;
  margin-bottom: 3rem;
}

.footer-title {
  font-family: "Moderustic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.mail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

.mail-icon {
  width: 1.5rem;
}

.socialmedia-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
  margin: 1.5rem 0 1.5rem 0;
}

svg {
  fill: black;
  width: 3rem;
  transition: fill 1s ease-out;
}

svg:hover {
  fill: #C78059;
  cursor: pointer;
  transition: fill 1s ease-in-out;
}


/* Surfplatta ---------------------------------------------------------------------------------> */
@media screen and (min-width: 496px) and (max-width: 768px) {
  /* Info - Högst upp */ 
  

  /* Projekt - Mitten */
  /* .project-image {
    width: 30%;
  } */

  .project-1, 
  .project-2, 
  .project-3, 
  .project-4, 
  .project-5 {
    flex-direction: column;
    padding: 0 3rem 0 3rem;
  }

  /* Skills - Mitten */

  .flex-item {
    flex: 1 1 calc(25% - 20px); /* 4 kolumner */
  }

}

@media screen and (min-width: 769px) and (max-width: 1023px) {


  /* Projekt - Mitten */
  .projects-title, 
  .skills-container h2 {
    font-size: 50px;
    position: relative;
    z-index: -1;
  }

  .project-text {
    color:#36462F;
  }

  /* .project-image {
    width: 35%;
  } */

  /* Skills */
  .flex-container {
    margin: 0 10rem 0 10rem;
  }

}

/* Större skärmar ---------------------------------------------------------------------------------> */
@media screen and (min-width:1024px)  and (max-width: 1400px) {
   /* Toggle */

   .react-toggle {
    display: block !important;
  }

  .react-toggle-thumb {
    background-color: #fff;
  }
  
  .react-toggle-track {
    background-color: #A1B999 !important;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #b9b9b9 !important; 
  }
  
  
  /* Knappar */

  .cv-button {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  }

  .contact-button {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    margin: 2rem 0 0 0;
    /* margin: 0 0 0 4rem; När det finns en CV-knapp gäller dessa regler. */
  }

  .darkmode-button {
    display: block;
    z-index: 1;
    position: fixed;
    top: 1rem;
    cursor: pointer;
    background-color: #F5EBC1;
    border: none;
    border-bottom: 3px solid #908A6E;
    padding: 0.5rem;
    border-radius: 0.2rem;
  }

  /* Info - Högst upp */
  .info-container {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin: 0 auto;
    max-width: 90rem;
    height: 56rem;
  }

  .text-container {
    margin: 10rem;
    /* width: 35rem; */
  }

  .text-container p {
    margin: 0 25rem 0 0;
  }

  .text-container h1 {
    font-size: 50px;
    color: #C78059;
    margin: 0 0 2rem 0;
  }

  .profile-image-container {
    display: block;
  }

  .profile-image {
    /* margin-right: 3rem; */
    border-radius: 10rem;
    border: solid #A1B999 0.5rem;
    float: right;
  }
  
  /* Projekt - Mitten */
  /* .project-image {
    width: 30%;
  } */
  
  .projects-container {
    background-color: #A1B999;
    padding: 0 0 7rem 0;
  }

  .projects-container h2, 
  .skills-container h2 {
    text-align: center;
    color: #3E2514;
    font-size: 60px;
    margin: 0;
  }

  .skills-container h2 {
    margin-bottom: 3rem;
  }
  
  .skills-container {
    background-color: #C78059;
    padding-top: 3rem;
    position: relative;
    z-index: -1;
  }

  .project-1, 
  .project-2, 
  .project-3, 
  .project-4, 
  .project-5 {
    display: flex;
    flex-direction: row;
    width: 50rem;
    column-gap: 1rem;
  }
  
  .project-info-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin: 0 auto;
    max-width: 40rem;
    flex-wrap: wrap;
    row-gap: 3rem;
  }

  .project-text {
    color: #36462F;
    margin: 0 10rem 0 2rem;
  }

  /* Skills - Mitten*/
  /* .flex-container {
    margin: 0 auto;
    max-width: 70rem;
    gap: 20px;
    padding: 10px;
  } */

  .flex-container {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin: 0 10rem 0 10rem;
  }


  .skill-icon {
    width: 3rem;
  }

  .skill-icon-php {
    width: 5rem;
  }

  .skill-icon-react, 
  .skill-icon-vue, 
  .skill-icon-laravel {
    width: 3.5rem;
  }

  .flex-item {
    flex: 1 1 calc(25% - 20px); /* 4 kolumner */
  }

}

@media screen and (min-width: 1401px) {
   /* Toggle */

   .react-toggle {
    display: block !important;
  }

  .react-toggle-thumb {
    background-color: #fff;
  }
  
  .react-toggle-track {
    background-color: #A1B999 !important;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #b9b9b9 !important; 
  }
  
  /* Knappar */

  .cv-button {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  }

  .contact-button {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    margin: 2rem 0 0 0;
    /* margin: 0 0 0 4rem; När det finns en CV-knapp gäller dessa regler. */
  }

  .darkmode-button {
    display: block;
    z-index: 1;
    position: fixed;
    top: 1rem;
    cursor: pointer;
    background-color: #F5EBC1;
    border: none;
    border-bottom: 3px solid #908A6E;
    padding: 0.5rem;
    border-radius: 0.2rem;
  }

  /* Info - Högst upp */
  .info-container {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin: 0 auto;
    max-width: 90rem;
    height: 56rem;
  }

  .text-container {
    margin: 10rem;
    /* width: 35rem; */
  }

  .text-container p {
    margin: 0 40rem 0 0;
  }

  .text-container h1 {
    font-size: 50px;
    color: #C78059;
    margin: 0 0 2rem 0;
  }

  .profile-image-container {
    display: block;
  }

  .profile-image {
    /* margin-right: 3rem; */
    border-radius: 10rem;
    border: solid #A1B999 0.5rem;
    float: right;
  }
  
  /* Projekt - Mitten */
  /* .project-image {
    width: 200%;
  } */

  .project-1, 
  .project-2, 
  .project-3, 
  .project-4, 
  .project-5 {
    flex-direction: row;
    /* padding: 0 3rem 0 3rem; */
  }

  .projects-container {
    background-color: #A1B999;
    padding: 0rem 0 7rem 0;
  }

  .projects-container h2 {
    text-align: center;
    color: #36462F;
    font-size: 60px;
    margin: 0;
  }

  .skills-container h2 {
    text-align: center;
    color: #3E2514;
    font-size: 60px;
    margin: 0;
  }

  .skills-container h2 {
    margin-bottom: 3rem;
  }
  
  .skills-container {
    background-color: #C78059;
    padding-top: 3rem;
    position: relative;
    z-index: -1;
  }

  .project-1, 
  .project-2, 
  .project-3, 
  .project-4, 
  .project-5 {
    display: flex;
    flex-direction: row;
    width: 50rem;
    column-gap: 4rem;
  }
  
  .project-info-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    flex-wrap: wrap;
    row-gap: 3rem;
    margin: 0 auto;
    max-width: 45rem;
  }

  .project-text {
    color: #36462F;
    /* margin: 0 10rem 0 2rem; */
  }

  /* Skills - Mitten*/
  /* .flex-container {
    margin: 0 auto;
    max-width: 70rem;
    gap: 20px;
    padding: 10px;
  } */

  .flex-container {
    margin: 0 15rem 0 15rem;
  }

  .skill-icon {
    width: 3rem;
  }

  .skill-icon-php {
    width: 5rem;
  }

  .skill-icon-react, 
  .skill-icon-vue, 
  .skill-icon-laravel {
    width: 3rem;
  }

  .flex-item {
    flex: 1 1 calc(25% - 20px); /* 4 kolumner */
  }

}

